
/**
 * 获取并存储code, 用于微信登录
 */
export function wxLogin() {
  return new Promise((resolve, reject) => {
    uni.login({
      provider: 'weixin',
      success: function (res) {
        resolve(res)
      },
      fail(e) {
        reject(e)
      }
    })
  })
}

export function showToast(msg) {
  uni.showToast({
    mask: true,
    icon: 'none',
    title: msg,
    duration: 1000
  })
}

export function hideLoading() {
  uni.hideLoading()
}

export function showLoading(msg) {
  uni.showLoading({
    title: msg
  });
}

/**
 * 获取存储
 * @param {String} key
 */
export function getStorage(key) {
  return new Promise((resolve, reject) => {
    uni.getStorage({
      key,
      success(res) {
        resolve(res.data)
      },
      fail(error) {
        reject(error)
      }
    })
  }).catch((e) => { })
}

/**
 * 设置存储
 * @param {String} key
 * @param {Object} data
 */
export function setStorage(key, data) {
  return new Promise((resolve, reject) => {
    uni.setStorage({
      key,
      data,
      success() {
        resolve()
      },
      fail(error) {
        reject(error)
      }
    })
  }).catch((e) => { })
}

/**
 * 移除存储
 * @param {String} key
 */
export function removeStorage(key) {
  return new Promise((resolve, reject) => {
    uni.removeStorage({
      key,
      success(res) {
        resolve(res)
      },
      fail(error) {
        reject(error)
      }
    })
  })
}

export function navigateTo(url) {
  uni.navigateTo({
    url
  })
}

export function redirectTo(url) {
  uni.redirectTo({
    url
  })
}

export function switchTab(url) {
  uni.switchTab({
    url
  })
}

export function navigateBack(index) {
  uni.navigateBack({
    delta: index | 1
  })
}

/**
 * 防抖
 * @param {Object} fn
 * @param {Object} delay
 */
export function debounce(fn, delay) {
  let timer = null //借助闭包
  return function () {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(fn, delay)
  }
}

export function Queue() {
  let items = []
  this.enqueue = function (item) {
    items.push(item)
  }
  this.dequeue = function () {
    return items.shift()
  }
  this.head = function () {
    return items[0]
  }
  this.size = function () {
    return items.length;
  }
  this.clear = function () {
    items = []
  }
  this.isEmpty = function () {
    return items.length === 0
  },
  this.print = function () {
    for (let i = 0; i < this.size(); i++) {
      console.log(items[i])
    }
  }
}

export function getUserIP(onNewIP) {
  let MyPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
  let pc = new MyPeerConnection({
    iceServers: []
  });
  let noop = () => {
  };
  let localIPs = {};
  let ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g;
  let iterateIP = (ip) => {
    if (!localIPs[ip]) onNewIP(ip);
    localIPs[ip] = true;
  };
  pc.createDataChannel('');
  pc.createOffer().then((sdp) => {
    sdp.sdp.split('\n').forEach(function (line) {
      if (line.indexOf('candidate') < 0) return;
      line.match(ipRegex).forEach(iterateIP);
    });
    pc.setLocalDescription(sdp, noop, noop);
  }).catch((reason) => {
  });
  pc.onicecandidate = (ice) => {
    if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return;
    ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
  };
}