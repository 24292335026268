import Vue from "vue";
import {
  Button,
  Slider,
  Tag,
  Popover,
  Image,
  Carousel,
  CarouselItem,
} from "element-ui";
import { ActionSheet, Popup } from "vant";

Vue.use(Button);
Vue.use(Slider);
Vue.use(Tag);
Vue.use(Popover);
Vue.use(Image);
Vue.use(ActionSheet);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Popup);
