import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/guide'
  },
  {
    path: '/guide',
    name: 'Guide',
    component: () => import('@/views/guide/index.vue')
  },
  {
    path: '/runRink',
    name: 'runRink',
    component: () => import('@/views/guide/runRink.vue')
  }, 
  {
    path: '/screen',
    name: 'Screen',
    component: () => import('@/views/screen/index')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
