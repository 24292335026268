export default {
  namespaced: true,
  state: {
    visibleInfo: false
  },
  mutations: {
    setVisibleInfo(state, visibleInfo) {
      state.visibleInfo = visibleInfo
    }
  },
  actions: {
  }
}