import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { get, post } from '@/axios/index'
import store from './store/index'
import api from '@/config/apis.js'
import utils from '@/utils/index.js'
import './plugins/element.js'
import 'element-ui/lib/theme-chalk/index.css'
import './plugins/vant.js'
import * as filters from '@/utils/filters'

Object.keys(filters).forEach(key => { Vue.filter(key, filters[key]) })
Vue.config.productionTip = false
Vue.prototype.$api = api
Vue.prototype.$utils = utils
Vue.prototype.$get = get
Vue.prototype.$post = post
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
