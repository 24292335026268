/**
 * js校验真实姓名
 * 规则:Unicode中文字符2-6位
 * @param {Object} name
 */
export function checkFullName(name) {
  if (!(/^[\u4e00-\u9fa5]{2,6}$/.test(name))) {
    return false
  }
  return true
}

//手机号码正则
export function checkPhone(phone) {
  if (!(/^1[3456789]\d{9}$/.test(phone))) {
    return false
  } else {
    return true
  }
}
//身份证号码正则
export function isIDCard(card) {
  if (!(/^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/.test(card))) {
    return false
  }

  const cardYear = Number((card + '').substr(6, 4))
    , cardMonth = Number((card + '').substr(10, 2))
    , cardDay = Number((card + '').substr(12, 2))
    , curYear = (new Date()).getFullYear()
  // 校验年龄<120岁
  if (curYear - cardYear >= 120) {
    return false
  }
  // 校验2月份闰年<=29天，平年<=28天
  if (cardMonth === 2) {
    if (isLeapYear(cardYear)) {
      return cardDay <= 29
    }
    return cardDay <= 28
  }

  return true
}

/**
 * 是否为闰年
 * @param {Object} year
 */
export function isLeapYear(year) {
  if ((year % 4 == 0 && year % 100 != 0) || (year % 400 == 0)) {
    return true
  }
  return false
}

/**
 * 邮箱
 * @param {*} s
 */
export function isEmail(s) {
  return /^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(s)
}


/**
 * 电话号码
 * @param {*} s
 */
export function isPhone(s) {
  return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(s)
}

/**
 * URL地址
 * @param {*} s
 */
export function isURL(s) {
  return /^http[s]?:\/\/.*/.test(s)
}

/**
 * 正整数
 * @param {*} s
 */
export function isNumber(s) {
  return /^\+?[1-9][0-9]*$/.test(s)
}

/**
 * 富文本
 * @param {*} html 
 */
export function formatRichText(html) {
  if (html) {
    let newContent = html.replace(/<img[^>]*>/gi, function (match, capture) {
      match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
      match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
      match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
      return match;
    });
    newContent = newContent.replace(/style="[^"]+"/gi, function (match, capture) {
      match = match.replace(/width:[^;]+;/gi, 'max-width:100%;').replace(/width:[^;]+;/gi, 'max-width:100%;');
      return match;
    });
    newContent = newContent.replace(/<br[^>]*\/>/gi, '');
    newContent = newContent.replace(/\<img/gi, '<img  style="display:block;margin-top:10px;margin-bottom:10px;border-radius:8px;"');

    // newContent = newContent.replace(/\<img/gi, '<img  style="width:100%;border-radius:8px;max-width:100%;height:auto;display:block;margin-top:10px;margin-bottom:10px;"');
    return newContent;
  } else {
    return ""
  }
}
