/**
 * 获取url路径参数
 * @param {Object} path
 */
export function getPathParam(path) {
  let obj = {}, reg = /[?&][^?&]+=[^?&]+/g;

  let arrs = path.match(reg);
  if (!arrs) {
    return;
  }

  arrs.forEach(item => {
    let tmpArr = item.substr(1).split('=');
    let key = encodeURIComponent(tmpArr[0]);
    let value = encodeURIComponent(tmpArr[1]);
    obj[key] = value;
  });

  return obj;
}

/**
 * 获取字符串字符个数
 * @param {Object} param
 */
export function strLen(param) {
  var j = 0;
  var s = param;
  if (s == "") return j;
  for (var i = 0; i < s.length; i++) {
    if (s.substr(i, 1).charCodeAt(0) > 255) j = j + 2;
    else j++
  }
  return j;
}

// 获得本周的开始日期和结束日期
export function getNowWeekDate() {
  let now = new Date();                    //当前日期    
  let nowDayOfWeek = now.getDay();         //今天本周的第几天    
  let nowDay = now.getDate();              //当前日    
  let nowMonth = now.getMonth();           //当前月    
  let nowYear = now.getFullYear();
  let start, end;
  if (nowDayOfWeek === 0) {
    start = nowDay - 6
    end = nowDay
  } else {
    start = nowDay - (nowDayOfWeek - 1)
    end = nowDay + (7 - nowDayOfWeek)
  }
  let weekStartDate = new Date(nowYear, nowMonth, start);
  let weekEndDate = new Date(nowYear, nowMonth, end);
  let monday = formatDate(weekStartDate, 'yyyy-MM-dd');
  let sunday = formatDate(weekEndDate, 'yyyy-MM-dd');
  let weekRange = [monday, sunday];
  return weekRange;
}

//获取当前年月日
export function getNowFormatDay(nowDate) {
  var char = "-";
  if (nowDate == null) {
    nowDate = new Date();
  }
  var day = nowDate.getDate();
  var month = nowDate.getMonth() + 1;//注意月份需要+1
  var year = nowDate.getFullYear();
  //补全0，并拼接
  return year + char + completeDate(month) + char + completeDate(day);
}

//获取当前时间，格式YYYY-MM-DD HH:mm:ss
export function getNowFormatTime() {
  var nowDate = new Date();
  var colon = ":";
  var h = nowDate.getHours();
  var m = nowDate.getMinutes();
  var s = nowDate.getSeconds();
  //补全0，并拼接
  return getNowFormatDay(nowDate) + " " + completeDate(h) + colon + completeDate(m) + colon + completeDate(s);
}

//补全0
export function completeDate(value) {
  return value < 10 ? "0" + value : value;
}

export function formatDate(date, fmt) {
	date = new Date((date + '').replace(/-/g, "/"))
	// date = new Date(date)
	if (/(y+)/.test(fmt)) {
	  fmt = fmt.replace(
		RegExp.$1,
		(date.getFullYear() + '').substr(4 - RegExp.$1.length)
	  )
	}
  
	var o = {
	  'M+': date.getMonth() + 1,
	  'd+': date.getDate(),
	  'h+': date.getHours(),
	  'm+': date.getMinutes(),
	  's+': date.getSeconds()
	}
  
	for (let k in o) {
	  if (new RegExp(`(${k})`).test(fmt)) {
		let str = o[k] + ''
		fmt = fmt.replace(
		  RegExp.$1,
		  RegExp.$1.length === 1 ? str : ('' + str).padStart(2, '0')
		)
	  }
	}
	return fmt
  }
