import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import Guide from './modules/Guide/Guide.js'


const store = new Vuex.Store({
  modules: {
    Guide
  },
  strict: process.env.NODE_ENV !== 'production'
})

export default store
