let appId, apiUrl, wsUrl, staticUrl, fileUrl, mapKey;
if (process.env.VUE_APP_NODE_ENV == "dev") {
  appId = "wx22b3483935eb5c88";
  apiUrl = "https://api-dev.yhsd.dhccity.com";
  wsUrl = "wss://api-dev.yhsd.dhccity.com";
  staticUrl = "https://admin-dev.yhsd.dhccity.com/static";
  fileUrl = "https://s3.dhccity.com/yanghushidi";
  mapKey = "VH2BZ-SV5C4-LVUUE-XAQ7X-NZELS-MKB4E";
} else if (process.env.VUE_APP_NODE_ENV == "prod:sit") {
  appId = "wx22b3483935eb5c88";
  apiUrl = "https://test.yhsd.dhccity.com";
  wsUrl = "wss://test.yhsd.dhccity.com";
  staticUrl = "https://test-yhsd-oss.dhccity.com/yanghushidi/static";
  fileUrl = "https://test-yhsd-oss.dhccity.com/yanghushidi";
  mapKey = "VH2BZ-SV5C4-LVUUE-XAQ7X-NZELS-MKB4E";
} else if (process.env.VUE_APP_NODE_ENV == "prod") {
  appId = "wx22b3483935eb5c88";
  apiUrl = "https://api.youke.yhsdjq.com";
  wsUrl = "wss://api.youke.yhsdjq.com";
  // staticUrl = "https://static.youke.yhsdjq.com/static";
  staticUrl = "https://yhsd-youke-static-1322933453.cos-website.ap-guangzhou.myqcloud.com/static";
  fileUrl = "https://cos.youke.yhsdjq.com";
  mapKey = "UDDBZ-BYOOD-RXI4D-HFAEI-TP3HJ-HDFLS";
} else {
  appId = "wx22b3483935eb5c88";
  apiUrl = "https://api-dev.yhsd.dhccity.com";
  wsUrl = "wss://api-dev.yhsd.dhccity.com";
  staticUrl = "https://admin-dev.yhsd.dhccity.com/static";
  fileUrl = "https://s3.dhccity.com/yanghushidi";
  mapKey = "VH2BZ-SV5C4-LVUUE-XAQ7X-NZELS-MKB4E";
}

const apis = {
  // 接口地址
  apiUrl: apiUrl,
  // WebSocket服务
  wsUrl: wsUrl,
  // 文件地址
  fileUrl: fileUrl,
  // 静态资源地址
  staticUrl: staticUrl,
  appId: appId,
  // 地图Key
  mapKey: mapKey,
  // 导览导航
  navListApi: "/api/scenicSpotGuide/getAllServiceManageList",
  //根据选择的服务类型查询服务列表
  getServiceListByTypeIdApi: "/api/scenicSpotGuide/getServiceManageInfo",
  //服务点详情
  getServiceDetailApi: "/api/scenicSpotGuide/getServiceManage",
  // 路线列表,
  getPathApi: "/api/scenicSpotGuide/getPath",
  // 手绘地图
  getDrawingMapApi: "/api/scenicSpotGuide/getHandMap",
  // 排行榜
  rank: "/api/sports/selectRankPage",
  // 大屏websocket服务
  screenApi: wsUrl + "/api/ws/screen/broadcast",
};
export default apis;
